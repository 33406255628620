/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

/* Angular Calendar */
@import "../node_modules/angular-calendar/css/angular-calendar.css";

/* ==================== MODALS ==================== */
.modal-general {
  --height: auto;
}
.modal-general::part(content) {
  --height: 70%;
  --width: 90%;
  --border-radius: 20px;
}

.modal-guardo-correctamente {
  --height: auto;
  --max-width: 410px;
}
.modal-guardo-correctamente::part(content) {
  --height: 240px;
  --width: 90%;
  --border-radius: 20px;
}

.modal-incidencia-enviada {
  --height: auto;
  --max-width: 410px;
}
.modal-incidencia-enviada::part(content) {
  --height: 410px;
  --width: 90%;
  --border-radius: 20px;
}

.custom-alert-popover::part(content) {
  --width: 70%;
}

/* ==================== LIGHT MODE ==================== */
/* (por defecto) */
ion-app {
  background-color: white;
}

a {
  text-decoration: underline;
}

/* Botón circular con ícono */
.btn-icon {
  background: #ffffff;
  box-shadow: 0px 1px 4px rgb(0 0 0 / 25%);
  border-radius: 27px;
  font-size: 11px;
  width: 40px;
  height: 40px;
  margin: 5px;

  ion-icon {
    color: #222428 !important;
    font-size: 20px;
  }

  &.active {
    background: #222428;
    ion-icon {
      color: #ffffff !important;
    }
  }
}

/* Botón volver */
.btn-volver {
  --background: #ffffff;
  --color: #222428;
  height: 45px;
  --border-radius: 9px;
  width: 100%;
  font-size: small;
  border: 1px solid #575757;
  box-sizing: border-box;
  margin-top: 2em;

  ion-icon {
    margin-right: 0.1em;
    color: #222428;
  }

  &.active {
    --background: #222428;
    ion-icon {
      color: #ffffff;
    }
  }
}

/* Tarjeta contenido */
.tarjeta-contenido {
  box-sizing: border-box;
  padding: 5px;
  //background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 13px;
}

/* Botones de fechas */
.botones-fechas {
  text-align: center;

  ion-button {
    border-radius: 5px;
    --border-radius: 5px;
    text-transform: capitalize;
    --background: #f4f4f4;
    color: #222428;

    &.active {
      --background: #858585;
      color: white;
    }
  }
}

/* Modal de IonDatetime personalizado */
.ion-modal-datetime {
  --width: 290px;
  --height: 350px;
  --border-radius: 8px;
}

/* Cursor pointer */
.cursor-pointer {
  cursor: pointer;
}

/* Subheader */
.subheader {
  display: flex;
  align-items: center;
  margin: 18px 0;

  ion-icon {
    margin-right: 10px;
  }
}

/* Opciones al deslizar (ion-item-options) */
ion-item-options {
  padding: 10px;

  ion-item-option {
    background: rgba(0, 0, 0, 0.05);

    ion-icon {
      color: black;
      padding: 8px;
      background: rgba(255, 255, 255, 0.8);
      box-shadow: 0px 1.5px 8px rgb(0 0 0 / 25%);
      border-radius: 70px;
      font-size: 20px;
    }
  }
}

/* Media queries */
@media (min-width: 320px) {
  /* smartphones, iPhone, portrait 480x320 phones */
}
@media (min-width: 481px) {
  /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
}
@media (min-width: 641px) {
  /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
  ion-app {
    width: 750px;
    margin: auto;
    border: 1px solid #ededed;
  }
}
@media (min-width: 961px) {
  /* tablet, landscape iPad, lo-res laptops and desktops */
}
@media (min-width: 1025px) {
  /* big landscape tablets, laptops, and desktops */
}
@media (min-width: 1281px) {
  /* hi-res laptops and desktops */
}

.color-red {
  color: var(--ion-color-danger);
}

/* ==================== FORMULARIOS ==================== */
.form-elementos {
  .elementos-una-fila {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: 10px 20px;
    flex-direction: row;

    .borde-elemento {
      margin-left: 0;
      margin-right: 0;
    }

    div {
      width: 50%;

      &.input {
        display: flex;
        justify-content: end;
      }
    }
  }

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #92949c;
    letter-spacing: 0.7px;
    margin: 2px;

    &.subtitulo {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      color: #000000;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      padding: 10px 0;
    }

    &.direccion {
      color: #000000;
      text-decoration: underline;

      ion-icon {
        float: right;
      }
    }
  }

  .borde-elemento {
    background: #ffffff;
    box-shadow: 0px 1px 4px rgb(0 0 0 / 25%);
    border-radius: 13px;
    margin: 10px 2px;
    margin-left: 20px;
    margin-right: 20px;
  }

  .con-unidades {
    --inner-padding-end: 0;

    .unidad {
      margin-left: 10px;
      background: #f3f3f3;
      min-width: 50px;
      height: 100%;
      text-align: center;
      display: block;
      padding: 15px 6px;
      color: #92949c;
    }
  }

  ion-select {
    background: #ffffff;
    box-shadow: 0px 1px 4px rgb(0 0 0 / 25%);
    border-radius: 13px;
    padding: 0 16px;
    margin: 10px 2px;
  }

  .select-opciones {
    margin: 10px 2px 33px;
    padding: 8px 16px;
  }

  .caja-fecha-visita {
    background: #ffffff;
    border: 1px solid #eaeaea;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 10px;
    margin-top: 10px;
  }

  .btns {
    margin: 40px 0px;

    ion-button {
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 20px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #222428;
      background: rgba(255, 255, 255, 0.8);
      border: 2px solid #d7d7d7;
      box-sizing: border-box;
      border-radius: 13px;
      width: 90%;
      margin: 10px auto;
      height: 50px;
    }
  }

  ion-radio-group {
    ion-item {
      background: #ffffff;
      box-shadow: 0px 1px 4px rgb(0 0 0 / 25%);
      border-radius: 8px;
      margin: 11px;
      font-size: 14px;

      .subitem {
        margin: 10px 0;
      }
    }
  }

  .input-text {
    margin: 0 auto;

    ion-item {
      background: #ffffff;
      box-shadow: 0px 1px 4px rgb(0 0 0 / 25%);
      border-radius: 8px;
      margin: 11px;
      font-size: 14px;

      .subitem {
        margin: 10px 0;
      }
    }
  }

  .subitem {
    width: 85%;
    margin: 0 auto;
  }

  .grupo-elementos {
    background: #f9f9f9;
    border: 0.5px solid #d1d1d1;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 8px;
    width: 90%;
    margin: 0 auto;

    ion-item {
      --background: transparent;
    }
  }

  .sub-grupo-radio {
    ion-item {
      --background: transparent;
      background: transparent;
      box-shadow: none;
    }
  }

  .errores {
    margin-bottom: 30px;

    .error {
      margin: 10px auto;
    }

    p {
      color: #f07383;

      &.titulo {
        font-weight: bold;
        text-decoration: underline;
      }
    }
  }

  .subtitulo-boton {
    position: relative;

    ion-button {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 10;
    }
  }
}

/* Para ocultar cosas con [hidden] */
[hidden] {
  display: none !important;
}

/* Ajuste global de ion-button */
ion-button {
  --border-radius: 14px;
}

/* Botón grande con ícono al extremo derecho */
.btn-large-icon {
  --color: #222428;
  height: 60px;
  width: 100%;
  text-align: initial;
  box-sizing: border-box;
  height: 64px;
  --background: #ffffff;
  --box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  --border-radius: 13px;
  --border: 1px solid #575757;
  --padding-start: 20px;
  --padding-end: 0;
  font-size: 16px;
  text-transform: none;
  margin-bottom: 16px;

  ion-icon {
    margin-right: 1em;
    color: #131313;
    margin-left: auto;
    font-size: 26px;
  }

  ion-label {
    display: flex;
    color: #5e5873;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: left;
  }

  &.active {
    background: #222428;
    ion-icon {
      color: #ffffff;
    }
  }
}

.espacio-form-buttom {
  margin-top: 40px;
}

app-nav-header {
  position: relative;
  z-index: 10;
  width: 100%;
  transition: transform 0.3s ease-in-out, position 0s 0.3s;

  &.header-hidden {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
    z-index: 100;
  }
}

/* ==================== DARK MODE OVERRIDES ==================== */
/* Cuando se aplique la clase .dark a ion-app, se sobreescriben los estilos para modo oscuro. */
ion-app.dark {
  background-color: #121212 !important;
  color: #ffffff !important;
}

/* Los enlaces en modo oscuro */
ion-app.dark a {
  color: #bdbdbd !important;
  text-decoration: underline;
}

/* Sobrescribe la tarjeta en modo oscuro */
ion-app.dark .tarjeta-contenido {
  background: #1f1f1f;
  box-shadow: 0px 1px 4px rgb(255 255 255 / 10%);
}

/* Botones con ícono */
ion-app.dark .btn-icon {
  background: #2c2c2c;
  box-shadow: 0px 1px 4px rgb(255 255 255 / 15%);

  ion-icon {
    color: #ffffff !important;
  }

  &.active {
    background: #575757;
    ion-icon {
      color: #ffffff !important;
    }
  }
}

/* Botón volver en modo oscuro */
ion-app.dark .btn-volver {
  --background: #2c2c2c;
  --color: #ffffff;
  border: 1px solid #616161;

  &.active {
    --background: #575757;
  }

  ion-icon {
    color: #ffffff;
  }
}

/* Botones de fechas */
ion-app.dark .botones-fechas ion-button {
  --background: #353535;
  color: #bdbdbd;

  &.active {
    --background: #575757;
    color: #ffffff;
  }
}

/* Botón grande con ícono */
ion-app.dark .btn-large-icon {
  --background: #2c2c2c;
  --box-shadow: 0px 1px 4px rgb(255 255 255 / 15%);
  --border: 1px solid #616161;

  ion-icon {
    color: #f1f1f1;
  }

  ion-label {
    color: #f1f1f1;
  }

  &.active {
    background: #575757;

    ion-icon {
      color: #ffffff;
    }
  }
}

/* Para elementos de formulario con fondo blanco, pasarlos a oscuro */
ion-app.dark .borde-elemento {
  background: #2c2c2c;
  box-shadow: 0px 1px 4px rgb(255 255 255 / 10%);
}

ion-app.dark ion-select {
  background: #2c2c2c !important;
}

ion-app.dark .caja-fecha-visita {
  background: #2c2c2c;
  border: 1px solid #444444;
}

ion-app.dark ion-item {
  background: #2c2c2c !important;
  box-shadow: 0px 1px 4px rgb(255 255 255 / 10%) !important;
}

ion-app.dark .grupo-elementos {
  background: #2c2c2c;
  border: 0.5px solid #444444;
}

ion-app.dark .errores p {
  color: #f28b8b;
}

ion-app.dark .select-opciones {
  background: #2c2c2c;
  color: #bdbdbd;
}

ion-app.dark .input-text ion-item {
  background: #2c2c2c !important;
}

ion-app.dark .subheader ion-icon {
  color: #f1f1f1;
}

/* Para item-options en modo oscuro */
ion-app.dark ion-item-options ion-item-option {
  background: rgba(255, 255, 255, 0.1) !important;
  ion-icon {
    background: rgba(0, 0, 0, 0.3) !important;
    color: #ffffff !important;
  }
}
