/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

@import "../node_modules/angular-calendar/css/angular-calendar.css";

.modal-general {
  --height: auto;
}

.modal-general::part(content) {
  --height: 70%;
  --width: 90%;
  --border-radius: 20px;
}

.modal-guardo-correctamente {
  --height: auto;
  --max-width: 410px;
}

.modal-guardo-correctamente::part(content) {
  --height: 240px;
  --width: 90%;
  --border-radius: 20px;
}

.modal-incidencia-enviada {
  --height: auto;
  --max-width: 410px;
}

.modal-incidencia-enviada::part(content) {
  --height: 410px;
  --width: 90%;
  --border-radius: 20px;
}

.custom-alert-popover::part(content) {
  --width: 70%;
}

ion-app {
  background-color: white;
}

a {
  text-decoration: underline;
}

.btn-icon {
  background: #ffffff;
  box-shadow: 0px 1px 4px rgb(0 0 0 / 25%);
  border-radius: 27px;
  font-size: 11px;
  width: 40px;
  height: 40px;
  margin: 5px;
  ion-icon {
    color: #222428 !important;
    font-size: 20px;
  }
  &.active {
    background: #222428;
    ion-icon {
      color: #ffffff !important;
    }
  }
}

.btn-volver {
  --background: #ffffff;
  --color: #222428;
  height: 45px;
  --border-radius: 9px;
  border-radius: 9px;
  width: 100%;
  font-size: small;
  border: 1px solid #575757;
  box-sizing: border-box;
  margin-top: 2em;
  ion-icon {
    margin-right: 0.1em;
    color: #222428;
  }
  &.active {
    --background: #222428;
    ion-icon {
      color: #ffffff;
    }
  }
}

.tarjeta-contenido{
  box-sizing: border-box;
  padding: 5px;
  background: #FFFFFF;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 13px;
}

.botones-fechas{
  text-align: center;
  ion-button{
    border-radius: 5px;
    --border-radius: 5px;
    text-transform: capitalize;
    --background: #f4f4f4;
    color: #222428;
    &.active {
      --background: #858585;
      color: white;
    }
  }
}

.ion-modal-datetime {
  --width: 290px;
  --height: 350px;
  --border-radius: 8px;
}

.cursor-pointer {
  cursor: pointer;
}

.subheader{
  display: flex;
  align-items: center;
  margin: 18px 0;
  ion-icon{
    margin-right: 10px;
  }
}

ion-item-options{
  padding: 10px;
  ion-item-option{
      background: rgba(0, 0, 0, 0.05);
      ion-icon{
          color: black;
          padding: 8px;
          background: rgba(255, 255, 255, 0.8);
          box-shadow: 0px 1.5px 8px rgb(0 0 0 / 25%);
          border-radius: 70px;
          font-size: 20px;
      }
  }
}

@media (min-width: 320px) {
  /* smartphones, iPhone, portrait 480x320 phones */
}
@media (min-width: 481px) {
  /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
}
@media (min-width: 641px) {
  /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
  ion-app {
    width: 750px;
    margin: auto;
    border: 1px solid #ededed;
  }
}
@media (min-width: 961px) {
  /* tablet, landscape iPad, lo-res laptops ands desktops */
}
@media (min-width: 1025px) {
  /* big landscape tablets, laptops, and desktops */
}
@media (min-width: 1281px) {
  /* hi-res laptops and desktops */
}


.color-red{
  color: var(--ion-color-danger)
}

.form-elementos{
  .elementos-una-fila{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: 10px 20px;
    flex-direction: row;
    .borde-elemento{
      margin-left: 0;
      margin-right: 0;
    }
    div{
      width: 50%;
      &.input{
        display: flex;
        justify-content: end;
      }
    }
  }
  p{
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      color: #92949C;
      letter-spacing: 0.7px;
      margin: 2px;
      &.subtitulo{
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 20px;
          color: #000000;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          padding: 10px 0;
      }
      &.direccion{
          color: #000000;
          text-decoration: underline;
          ion-icon{
              float: right;
          }
      }
  }

  .borde-elemento{
      background: #FFFFFF;
      box-shadow: 0px 1px 4px rgb(0 0 0 / 25%);
      border-radius: 13px;
      margin: 10px 2px;
      margin-left: 20px;
      margin-right: 20px;
  }
  .con-unidades{
      --inner-padding-end: 0;
      .unidad{
          margin-left: 10px;
          background: #F3F3F3;
          min-width: 50px;
          height: 100%;
          text-align: center;
          display: block;
          padding: 15px 6px;
          color: #92949C;
      }
  }
  
  ion-select{
      background: #FFFFFF;
      box-shadow: 0px 1px 4px rgb(0 0 0 / 25%);
      border-radius: 13px;
      padding: 0 16px;
      margin: 10px 2px;
  }
  
  .select-opciones{
      margin: 10px 2px 33px;
      padding: 8px 16px;
  }
  
  .caja-fecha-visita{
      background: #FFFFFF;
      border: 1px solid #EAEAEA;
      box-sizing: border-box;
      border-radius: 8px;
      padding: 10px;
      margin-top: 10px;
  }
  
  .btns{
      margin: 40px 0px;
      ion-button{
          font-style: normal;
          font-weight: 600;
          font-size: 13px;
          line-height: 20px;
          display: flex;
          align-items: center;
          text-align: center;
          color: #222428;
          background: rgba(255, 255, 255, 0.8);
          border: 2px solid #D7D7D7;
          box-sizing: border-box;
          border-radius: 13px;
          width: 90%;
          margin: 10px auto;
          height: 50px;
      }
  }
  
  ion-radio-group{
      ion-item{
          background: #FFFFFF;
          box-shadow: 0px 1px 4px rgb(0 0 0 / 25%);
          border-radius: 8px;
          margin: 11px;
          font-size: 14px;
          .subitem{
              margin: 10px 0;
          }
      }
  }
  .input-text{
      margin: 0 auto;
      ion-item{
          background: #FFFFFF;
          box-shadow: 0px 1px 4px rgb(0 0 0 / 25%);
          border-radius: 8px;
          margin: 11px;
          font-size: 14px;
          .subitem{
              margin: 10px 0;
          }
      }
  }
  .subitem{
      width: 85%;
      margin: 0 auto;
  }
  
  .grupo-elementos{
      background: #F9F9F9;
      border: 0.5px solid #D1D1D1;
      box-sizing: border-box;
      border-radius: 8px;
      padding: 8px;
      width: 90%;
      margin: 0 auto;
      ion-item{
          --background: transparent;
      }
  }
  
  .sub-grupo-radio{
      ion-item{
          --background: transparent;
          background: transparent;
          box-shadow: none;
      }
  }
  
  .errores{
      margin-bottom: 30px;
      .error{
          margin: 10px auto;
      }
      p{
          color: #F07383;
          &.titulo{
              font-weight: bold;
              text-decoration: underline;
          }
      }
  }
  .subtitulo-boton{
    position: relative;
    ion-button{
        position: absolute;
        top: 0;
        right: 0;
        z-index: 10;
    }
  }
}
[hidden] {
  display: none !important;
}

ion-button{
  --border-radius: 14px;
}


.btn-large-icon {
  --color: #222428;
  height: 60px;
  width: 100%;
  text-align: initial;
  box-sizing: border-box;
  height: 64px;
  --background: #ffffff;
  --box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  --border-radius: 13px;
  --border: 1px solid #575757;
  --padding-start: 20px;
  --padding-end: 0;
  font-size: 16px;
  text-transform: none;
  margin-bottom: 16px;

  ion-icon {
    margin-right: 1em;
    color: #131313;
    margin-left: auto;
    font-size: 26px;
  }
  ion-label {
    display: flex;
    color: #5e5873;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: left;
  }

  &.active {
    background: #222428;
    ion-icon {
      color: #ffffff;
    }
  }
}

.espacio-form-buttom{
  margin-top: 40px;
}

app-nav-header {
  position: relative;
  z-index: 10;
  width: 100%;
  transition: transform 0.3s ease-in-out, position 0s 0.3s;
  
  &.header-hidden {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100%);
    z-index: 100;
  }
}